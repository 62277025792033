/* CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box; /* Ensure padding and borders are included in element sizes */
}
 
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
 
body {
    line-height: 1;
}
 
ol, ul {
    list-style: none;
}
 
blockquote, q {
    quotes: none;
}
 
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
 
table {
    border-collapse: collapse;
    border-spacing: 0;
}
 
/* Custom Styles */
body {
    background: white;
    color: black;
    font-family: 'Open Sans', sans-serif;
}

/* styles.css */
.centered-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 使容器高度为视口高度 */
}
